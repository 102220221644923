import React from "react";
import './BlogPost.css';
import ConfettiExplosion from "react-confetti-explosion";

const blog = {
    title: "WHY DID I QUIT MY JOB AND DECIDE TO GO ON SABBATICAL?",
}

export function WhyDidIQuitMyJob() {
    const [motiveVisibility, setMotiveVisibility] = React.useState('hidden');
    const [buttonText, setButtonText] = React.useState('DESCRIPTIVE BUTTON TEXT SHOULD BE HERE BUT IT IS NOT. SORRY.')
    const [secondButtonText, setSecondButtonText] = React.useState('DESCRIPTIVE BUTTON TEXT SHOULD BE HERE BUT IT IS NOT. SORRY.')
    const [whatHappensNextText, setWhatHappensNextText] = React.useState('DESCRIPTIVE BUTTON TEXT SHOULD BE HERE BUT IT IS NOT. SORRY.');
    const [moreInformationVisibility, setMoreInformationVisibility] = React.useState('hidden');
    const [whatHappensNextVisibility, setWhatHappensNextVisibility] = React.useState('hidden');
    const [problemsVisibility, setProblemsVisibility] = React.useState('hidden');
    const [isExploding, setIsExploding] = React.useState(false);

    React.useEffect(() => {
        if (motiveVisibility === 'hidden') {
            setButtonText('REVEAL MY MOTIVES');
            setIsExploding(false);
        } else {
            setButtonText('HIDE MY MOTIVES');
            setIsExploding(true);
        }
    }, [motiveVisibility])

    React.useEffect(() => {
        if (moreInformationVisibility === 'hidden') {
            setSecondButtonText('REVEAL A LITTLE MORE');
        } else {
            setSecondButtonText('YOU HAVE REVEALED TOO MUCH, HIDE THE INFORMATION!')
        }
    }, [moreInformationVisibility]);

    React.useEffect(() => {
        if (whatHappensNextVisibility === 'hidden') {
            setWhatHappensNextText('WHAT HAPPENED NEXT?!');
            setIsExploding(false);
        } else {
            setWhatHappensNextText('I AM SHOOK. TAKE IT BACK.')
            setIsExploding(true);
        }
    }, [whatHappensNextVisibility]);

    return (
        <div className="blog-background">
            <div className="take-me-home-link">
                <a href="/">
                    <div className="button take-me-home-button">
                        <h3>TAKE ME HOME</h3>
                    </div>
                </a>
            </div>
            
            <div className="blog-post">
                <h1>{blog.title}</h1>
                <div className='button inline-button' onClick={() => setMotiveVisibility(motiveVisibility === 'visible' ? 'hidden' : 'visible')}>
                    <h1>{buttonText}</h1>
                </div>
                <div style={{position: 'fixed', top: 0, left: '50%'}}>{isExploding &&
                    <ConfettiExplosion force={0.8} duration={3000} particleCount={50} width={1600}/>
                }</div>
                <h2 style={{visibility: motiveVisibility}}>BECAUSE I WANTED TO</h2>
                {motiveVisibility === 'visible' && 
                    <div>
                        <p>Well, there's a little more to it than that...</p>
                        <div className='button inline-button' onClick={() => setMoreInformationVisibility(moreInformationVisibility === 'visible' ? 'hidden' : 'visible')}>
                            <h1>{secondButtonText}</h1>
                        </div>
                        {moreInformationVisibility === 'visible' && 
                            <div>
                                <p>I was fortunate enough to have the means to do so.</p>
                                <p>I had a job that let me save enough money to have time off. I had family and friends who were supportive. I had family that I could move in with to save money.</p>
                                <p>It isn't that big of a risk when you have so many safety nets, and I am fortunate and grateful to have them.</p>
                                <p>I had a lot of things going well for me in 2023:</p>
                                <ul>
                                    <li>A decent job as a Software Engineer.</li>
                                    <li>A nice rental in North Adelaide with my partner, Sarah.</li>
                                    <li>Many wonderful friends.</li>
                                    <li>A swimming community that I adore.</li>
                                </ul>
                                <p>But I had a job that wasn't aligned with my values and I was questioning my career choices entirely.</p>
                                <p>My job was taking far more energy from me than I was getting from it.</p>
                                <p>Alexa, play me the world's tiniest violin.</p>
                                <p>One day in August, I had a GP appointment that ran late. I was in the waiting room for 2 hours. When I got in to work, my first thought was:</p>
                                <h2>I WOULD RATHER BE AT THE GP THAN HERE</h2>
                                <div className='button inline-button' onClick={() => setWhatHappensNextVisibility(whatHappensNextVisibility === 'visible' ? 'hidden' : 'visible')}>
                                    <h1>{whatHappensNextText}</h1>
                                </div>
                                {whatHappensNextVisibility === 'visible' && 
                                    <div>
                                        <p>I quit.</p>
                                        <p>Originally I planned to take the rest of the year off and do some part time work and study in 2024.</p>
                                        <p> But as I recovered from my career hangover and started to work through my Quarter Life Crisis, Sarah started seriously considering doing the same thing.</p>
                                        <p>We had always planned to take a sabbatical before we turned 30, so we thought, why not do it now?</p>
                                        <div className='button inline-button' onClick={() => setProblemsVisibility(problemsVisibility === 'visible' ? 'hidden' : 'visible')}>
                                            <h1>WAIT, ARE YOU JUST RUNNING AWAY FROM YOUR PROBLEMS?</h1>
                                        </div>
                                        {problemsVisibility === 'visible' && 
                                            <div>
                                                <h2>YES</h2>
                                                <p>I'm putting off a career change and giving myself some space to explore the world and meet new people</p>
                                                <p>God, it's such a mid-20s cliche. I hate it.</p>
                                                <p>Maybe I'll find my purpose on a mountain in Nepal, doing ayahuasca in South America, or sipping an espresso on the Italian coast.</p>
                                                <p>But it's more likely that I'll just travel until I run out of money and then look for a job at home or wherever I am at the time.</p>
                                                <p>I'll fall into some new rhythm that's a little better than the old one and life will go on.</p>
                                                <p>There won't be any magical epiphanies or sudden discovery of some hidden talent.</p>
                                                <p>And that's okay, at least I'll have some fun.</p>
                                                <p>Now I would like to live out a mid-teens cliche that I was never able to live: Quoting song lyrics that mean a lot to me on my personal website.</p>
                                                <h2>"You might put your love and trust on the line</h2>
                                                <h2>It's risky, people love to tear that down</h2>
                                                <h2>Let 'em try</h2>
                                                <h2>Do it anyway</h2>
                                                <h2>Risk it anyway"</h2>
                                                <p>---From <i>Do It Anyway</i> by Ben Folds</p>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}
import { PhotoProvider, PhotoView } from 'react-photo-view';

export function PhotoGallery() {
    return (
        <div className='photo-list'>
            <h1>I HAVE NEITHER THE TIME NOR THE PATIENCE FOR WORDS I WANT ONLY PHOTOS</h1>
            <PhotoProvider>
            <div className="photo-thumbnail">
                <div>
                <PhotoView key={1} src={"photos/vietnam/vietnam1.jpg"}>
                    <img src={"photos/vietnam/vietnam1.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={2} src={"photos/vietnam/vietnam2.jpg"}>
                    <img src={"photos/vietnam/vietnam2.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={3} src={"photos/vietnam/vietnam3.jpg"}>
                    <img src={"photos/vietnam/vietnam3.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={4} src={"photos/vietnam/vietnam4.jpg"}>
                    <img src={"photos/vietnam/vietnam4.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={5} src={"photos/vietnam/vietnam5.jpg"}>
                    <img src={"photos/vietnam/vietnam5.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={6} src={"photos/vietnam/vietnam6.jpg"}>
                    <img src={"photos/vietnam/vietnam6.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={7} src={"photos/vietnam/vietnam7.jpg"}>
                    <img src={"photos/vietnam/vietnam7.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={8} src={"photos/vietnam/vietnam8.jpg"}>
                    <img src={"photos/vietnam/vietnam8.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={9} src={"photos/vietnam/vietnam9.jpg"}>
                    <img src={"photos/vietnam/vietnam9.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={10} src={"photos/vietnam/vietnam10.jpg"}>
                    <img src={"photos/vietnam/vietnam10.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={11} src={"photos/vietnam/vietnam11.jpg"}>
                    <img src={"photos/vietnam/vietnam11.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={12} src={"photos/vietnam/vietnam12.jpg"}>
                    <img src={"photos/vietnam/vietnam12.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={13} src={"photos/vietnam/vietnam13.jpg"}>
                    <img src={"photos/vietnam/vietnam13.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
                <div>
                <PhotoView key={14} src={"photos/vietnam/vietnam14.jpg"}>
                    <img src={"photos/vietnam/vietnam14.jpg"} style={{ objectFit: 'cover'}} alt="" />
                </PhotoView>
                </div>
            </div>
            </PhotoProvider>
      </div>);
}
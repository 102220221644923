import React from "react";
import './CoffeeReviews.css';

const coffeeReviews = [
    {
        title: "HOMEMADE BY MAMA",
        text: [
            "In the coffee capital of Vietnam, the best place to enjoy a coffee is in the veggie garden, surrounded by bugs, with a couple of cats and dogs wandering around.",
            "We stayed in a lovely homestay where the mama made me all the coffee I could want and it was delicious",
            "Sweet, but not too sweet. Bitter, but not too bitter.",
            " Just what I needed after being woken up by the very loud and angry goose next door."
        ],
        isRecommended: true,
        photoLink: "/"
    },
    {
        title: "WEASEL ARABICA",
        text: [
            "Weasel coffee is made by feeding ripe coffee beans to weasels and processing their excrement. Yes, really.", 
            "The weasels here seem to be treated well. They have space to roam, plenty of food and receive veterinarian care. I can't speak for other places you might get weasel coffee.",
            "I had the coffee iced and black. No sugar.",
            "It had a smooth bitter taste that wasn't overwhelming. There wasn't really any sweetness or sourness to it, which I appreciate.",
            "Just a good consistent coffee flavour.",
            "Though I don't think its so good we need to be keeping weasels in captivity for it."
        ],
        isRecommended: null,
        photoLink: "/"
    },
    {
        title: "THE WORLD COFFEE MUSEUM",
        text: [
            "The World Coffee Museum is somewhat architecturally interesting, but the space is underutilized and the exhibits are underwhelming.",
            "The coffee there was very burnt. I didn't finish it."
        ],
        isRecommended: false,
        photoLink: "/"
    }
]

export function CoffeeReviews() {
    const [activeReviewIndex, setActiveReviewIndex] = React.useState(0);

    return (
        <div className="blog-background coffee-reviews">
            <div className="take-me-home-link">
                <a href="/">
                    <div className="button take-me-home-button">
                        <h3>TAKE ME HOME</h3>
                    </div>
                </a>
            </div>
            <div className="review-list">
                {coffeeReviews.map((review, reviewIndex) => {
                    return (
                        <div className="review-item">
                            <h1>{review.title}</h1>
                            {review.text.map((paragraph) => {
                                return <p>{paragraph}</p>
                            })}
                            {review.isRecommended === true ? <img src="textures/seal_of_approval.png"/> : <div/>}
                            {review.isRecommended === false ? <img src="textures/seal_of_disapproval.png"/> : <div/>}
                            {review.isRecommended === null ? <img src="textures/seal_of_fence.png"/> : <div/>}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
import './App.css';
import React from 'react';
import { HorizontalScrollingText } from './components/HorizontalScrollingText/HorizontalScrollingText';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Earth } from './components/Earth/Earth';
import { BlogCard } from './components/BlogCard/BlogCard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WhyDidIQuitMyJob } from './components/BlogPost/WhyDidIQuitMyJob';
import { PhotoGallery } from './components/PhotoGallery/PhotoGallery';
import { CoffeeReviews } from './components/CoffeeReviews/CoffeeReviews';

/**
 * Camera
 */
let camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.y = 0;
camera.position.x = 1;
camera.position.z = 5;

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <HorizontalScrollingText
          fontSizePixels={72}
          text={"ON SABBATICAL"}
          textMargin={24}
          textWidth={500}
        />
      </header>
      <div className='summary'>
        <div></div>
        <div>
          <h2>CURRENTLY IN</h2>
          <HorizontalScrollingText 
            fontSizePixels={64}
            text={"THAILAND"}
            textMargin={24}
            textWidth={280}
          />
        </div>
        <div></div>
        <div>
          <h2>MOOD</h2>
          <HorizontalScrollingText 
            fontSizePixels={64}
            text={"WET AND WILD"}
            textMargin={24}
            textWidth={650}
          />
        </div>
      </div>
      <div id="canvas-container">
        <Canvas
          camera={camera}
        >
          <Earth></Earth>
          <ambientLight intensity={10} color={'#ffffff'} />
        </Canvas>
      </div>
      <div id='padding-after-summary'></div>
      <div className='blog-list'>
        <h1>I WANT TO READ WORDS ABOUT BEING ON SABBATICAL</h1>
        <BlogCard title={"WHY DID I QUIT MY JOB AND DECIDE TO GO ON SABBATICAL?"} link={"/why-i-did-it"}></BlogCard>
        <BlogCard title={"I LIKE COFFEE, SO HERE ARE SOME REVIEWS"} link={"/coffee-reviews"}></BlogCard>
        {/* <div className='button'>
          <h1>CLICK HERE TO CONSUME MORE WRITTEN WORDS</h1>
        </div> */}
      </div>
      <PhotoGallery/>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path='why-i-did-it' index element={<WhyDidIQuitMyJob />} />
        <Route path='coffee-reviews' index element={<CoffeeReviews />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import './HorizontalScrollingText.css';

export function HorizontalScrollingText({fontSizePixels, text, textMargin, textWidth}) {
    const [textOffset, setTextOffset] = React.useState(0);

    React.useEffect(() => {
        setInterval(() => {      
            setTextOffset(textOffset => textOffset + 1);
        }, 20);
    }, []);

    const textArray = Array(6).fill(text);

    if (textOffset > (textWidth + textMargin)) {
        setTextOffset(0);
    }

    return (
        <div className="horizontal-scrolling-container">
            {
                textArray.map((text, index) => (
                    <p 
                        key={index}
                        style={{
                            left: (textMargin - textOffset) + (textWidth + textMargin) * index, 
                            width: textWidth,
                            fontSize: fontSizePixels
                        }}
                    >
                        {text}
                    </p>
                ))
            }
        </div>
    )
}
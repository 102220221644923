import React from "react";
import './BlogCard.css';

export function BlogCard({title, link}) {
    return (
        <div>
            <div className="blog-card-container">
                <a href={link}>
                    <div className="blog-card">
                        <h2>{title}</h2>
                        <div className="blog-card-shadow"></div>
                    </div>
                </a>
            </div>
        </div>
    );
}
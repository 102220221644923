import React from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from 'three';

export function Earth() {
    const earthMesh = React.useRef();
    const colorMap = useLoader(THREE.TextureLoader, 'textures/earth.png');

    const countryMesh = React.useRef();
    const countryColorMap = useLoader(THREE.TextureLoader, 'textures/thailand.png');

    React.useEffect(() => {
        // Initial rotation position
        const xRotation = 0;
        const yRotation = -2.9;
        earthMesh.current.rotation.y = yRotation;
        countryMesh.current.rotation.y = yRotation;
        earthMesh.current.rotation.x = xRotation;
        countryMesh.current.rotation.x = xRotation;

        // Rotating Earth
        setInterval(() => {      
            earthMesh.current.rotation.y += 0.001;
            countryMesh.current.rotation.y += 0.001;
        }, 20);
    }, []);

    React.useEffect(() => {
        
    }, []);

    return (
        <group>
            <mesh ref={earthMesh}>
                <sphereGeometry args={[1, 64, 64]} />
                <meshBasicMaterial color={'#ffffff'} map={colorMap} toneMapped={false} />
            </mesh>
            <mesh ref={countryMesh}>
                <sphereGeometry args={[1.1, 64, 64]} />
                <meshBasicMaterial color={'#ff0000'} map={countryColorMap} alphaMap={countryColorMap} side={THREE.DoubleSide} transparent={true} />
            </mesh>
        </group>
    );
}